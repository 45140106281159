/*
 * File: batch-overview.page.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useState } from "react";
import { PerformanceTabContent as BatchPerformance } from "./components/performance/batch-performance.component";
import { BatchProgress } from "./components/progress/batch-progress.component";
import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import { IconEdit } from "components/common/vb-icon.component";
import { BatchDetailHeader } from "../../batch-detail.page";
import { useBatchDetailContext } from "../../context/batch-detail.context";

enum OverViewTab {
  PROGRESS = "progress",
  PERFORMANCE = "performance",
}

export const BatchOverviewPage = () => {
  const { t } = useTranslation();
  const { batch, onClickEditBatch } = useBatchDetailContext();
  const [activeTab, setActiveTab] = useState<string>(OverViewTab.PROGRESS);
  return (
    <div>
      <div className="flex justify-between">
        <div className="px-4 my-4 bg-white">
          <div className="flex items-center gap-2">
            <span className="text-lg font-bold">{batch?.name}</span>
            <button onClick={onClickEditBatch}>
              <IconEdit className="flex-none w-5 h-5 text-primary" />
            </button>
          </div>
          <div>
            {batch?.description && (
              <div className="space-y-1.5 my-2">
                <div className="text-xs text-background-500">
                  {t(
                    "project:batchDetails.overview.textDescriptionDescription"
                  )}
                </div>
                <p
                  className="text-sm break-words whitespace-normal"
                  dangerouslySetInnerHTML={{
                    __html: batch?.description || "",
                  }}
                />
              </div>
            )}
            {batch?.instruction && (
              <div className="space-y-1.5 my-2">
                <div className="text-xs text-background-500">
                  {t("project:batchDetails.overview.textInstruction")}
                </div>
                <p
                  className="text-sm break-words whitespace-normal"
                  dangerouslySetInnerHTML={{
                    __html: batch?.instruction || "",
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <BatchDetailHeader />
      </div>

      <div className="my-4">
        <VBTabs
          tabs={[
            {
              id: OverViewTab.PROGRESS,
              name: t("project:batchDetails.overview.textProgress"),
            },
            {
              id: OverViewTab.PERFORMANCE,
              name: t("project:batchDetails.overview.textPerformance"),
            },
          ]}
          activeTab={activeTab}
          onSelect={setActiveTab}
        />
        <div>
          {activeTab === OverViewTab.PROGRESS && <BatchProgress />}
          {activeTab === OverViewTab.PERFORMANCE && <BatchPerformance />}
        </div>
      </div>
    </div>
  );
};
