import { useCallback } from "react";
import { VBInput } from "pages/labeler/labeler-profile/components/vb-input.component";
import { VBRichTextEditor } from "components/common/vb-rich-text/vb-rich-text.component";
import { uploadResource } from "services/storage/apis/resource.api";
import { TicketSupportEntity } from "./types";
import * as Sentry from "@sentry/react";

interface TicketSupportFormProps {
  title?: React.ReactNode;
  values?: TicketSupportEntity;
  setValues?: (values: TicketSupportEntity) => void;
  setLoading?: (loading: boolean) => void;
}

export const TicketSupportForm = ({
  title,
  values,
  setValues,
  setLoading,
}: TicketSupportFormProps) => {

  const setFieldChange = useCallback(
    (key: string, value: any) => {
      setValues && setValues({ ...values, [key]: value });
    },
    [setValues, values]
  );

  const handleUploadImage = async (file: File) => {
    try {
      setLoading && setLoading(true);
      const resource = await uploadResource(file);
      if (resource.resourceId) {
        return resource.downloadUrl;
      }
      return ""
    } catch (error) {
      Sentry.captureException(error);
      console.log('Error', error);
    } finally {
      setLoading && setLoading(false)
    }
  }

  return (
    <section className="container space-y-8 bg-white dark:bg-black text-black-500">
      {title === null ? null : (
        <>
          <section className="text-center dark:text-white">
            <h1 className="text-lg font-bold leading-7">
              It looks like we're having some internal issues.
            </h1>
            <p className="text-sm font-semibold opacity-80">
              Our team has been notified. If you'd like to help, tell us what
              happened below.
            </p>
          </section>
        </>
      )}

      <section className="flex flex-col gap-y-6">
        <section className="space-y-3">
          <label className="text-sm font-bold">Title</label>
          <VBInput
            label=""
            placeholder="Title"
            className="rounded focus:ring-primary focus:border-primary"
            value={values?.title || ""}
            onValueChanged={(value) => setFieldChange("title", value)}
          />
        </section>

        <section className="space-y-3">
          <label className="text-sm font-bold">What happened?</label>

          <VBRichTextEditor
            containerClass="h-40 text-sm"
            defaultValue={values?.description || ""}
            onChange={(value) => {
              setFieldChange("description", value)
            }}
            onUpload={handleUploadImage}
          />
        </section>
      </section>
    </section>
  );
};
