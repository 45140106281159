/*
 * File: create-workflow.page.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 25th August 2021 2:23:32 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { Portal } from "@material-ui/core";
import { VBRichTextEditor } from "components/common/vb-rich-text/vb-rich-text.component";
import { VBSpinner } from "components/common/vb-spinner/vb-spinner.component";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import { useMemo } from "react";
import { useRef } from "react";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { WorkflowInstructionDTO } from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { createWorkflowInstructionsAsync } from "store/customer/workflow/workflow.thunk";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { StepTransitions } from "../instructions/step-transitions.component";
import { WorkflowInstructionModal } from "../instructions/workflow-instruction.modal";
import { getNextStepTypes } from "../workflows.util";
import * as Sentry from "@sentry/react";

type Instruction = Partial<WorkflowInstructionDTO>;

export const CreateWorkflowPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionDataRef = useRef("");
  const [open, setOpen] = useState(false);
  const [instructions, setInstructions] = useState<Instruction[]>([]);
  const [instruction, setInstruction] = useState<Instruction | null>(null);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const isRequesting = useMemo(
    () => requestStatus === RequestStatus.LOADING,
    [requestStatus]
  );
  const nextStepTypes = getNextStepTypes(instructions);
  const disabledAddStep = useMemo(() => !nextStepTypes || !nextStepTypes.length, [nextStepTypes]);

  async function handleCreate() {
    if (requestStatus === RequestStatus.LOADING) return;
    setRequestStatus(RequestStatus.LOADING);
    try {
      const workflow = {
        name: nameRef.current?.value || "",
        description: descriptionDataRef.current || "",
      };
      inputValidation(workflow);
      const payload = {
        workflow: workflow,
        instructions: instructions.map((ins) => {
          const instruction = { ...ins };
          delete instruction.id;
          return instruction;
        }),
      };
      const response = await dispatch(createWorkflowInstructionsAsync(payload));
      handleThunkRejected(response);
      setRequestStatus(RequestStatus.SUCCESS);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      handleClose();
    } catch (error: any) {
      Sentry.captureException(error);
      setRequestStatus(RequestStatus.FAILURE);
      const errMessage = error.message || t("common:textCreatedFailed");
      dispatch(enqueueErrorNotification(errMessage));
    }
  }

  function inputValidation(data: { name: string; description: string }) {
    if (!data.name.trim())
      throw new Error(t("workflow:createPage.textNameRequired"));
  }

  function handleClose() {
    history.push(Routes.WORKFLOWS);
  }

  function handleDescriptionChange(data: string) {
    descriptionDataRef.current = data;
  }

  function onSelectInstruction(instruction: Instruction) {
    setInstruction(instruction);
  }

  function handleEditInstruction(instruction: Instruction) {
    setInstructions([
      ...instructions.map((ins) => {
        if (ins.step === instruction.step) {
          return instruction;
        }
        return ins;
      }),
    ]);
    handleCloseInstructionModal();
  }

  function handleCloseInstructionModal() {
    setOpen(false);
    setInstruction(null);
  }

  function handleAddInstruction(instruction: Instruction) {
    setInstructions([
      ...instructions,
      { ...instruction, id: instructions.length + 1 },
    ]);
    setOpen(false);
  }

  return (
    <Portal container={document.body}>
      <div
        style={{ zIndex: 1299 }}
        className={`fixed top-0 left-0 w-screen h-screen bg-white ${isRequesting ? "select-none pointer-events-none" : ""
          }`}
      >
        <button
          className="absolute flex items-center justify-center w-10 h-10 rounded hover:bg-gray-100 left-4 top-4"
          onClick={handleClose}
          disabled={isRequesting}
        >
          <i className="text-2xl uir-arrow-small-left"></i>
        </button>
        <div
          className="py-4 mx-auto mt-12 border rounded"
          style={{ maxWidth: "48rem" }}
        >
          <div className="px-8">
            <div className="py-2 text-2xl font-bold border-b">
              {t("workflow:createPage.title")}
            </div>
          </div>
          <div className="px-8 my-4 text-lg font-bold">
            1. {t("workflow:createPage.textGeneralInfo")}
          </div>
          <div className="px-8">
            <form>
              <div className="grid items-center grid-cols-12">
                <div className="col-span-3 px-4 my-4 text-right">
                  {t("workflow:createPage.textName")}{" "}
                  <span className="text-red-500">*</span>
                </div>
                <div className="col-span-9">
                  <input
                    autoFocus
                    required
                    ref={nameRef}
                    type="text"
                    id="name"
                    placeholder={t("workflow:createPage.placeholderName")}
                    className="w-full px-2 py-2 border border-gray-200 rounded"
                  />
                </div>

                <div className="h-full col-span-3 px-4 my-4 text-right">
                  {t("workflow:createPage.textDescription")}
                </div>
                <div className="col-span-9">
                  <VBRichTextEditor
                    defaultValue=""
                    onChange={handleDescriptionChange}
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="flex items-center px-8 my-4">
            <div className="flex-1 text-lg font-bold">
              2. {t("workflow:createPage.textSteps")}
            </div>
            <div
              className={classnames({
                "pointer-events-none": disabledAddStep,
              })}
            >
              <button
                className={classnames(
                  "px-4 py-2",
                  disabledAddStep
                    ? "text-gray-300 cursor-not-allowed"
                    : "text-primary cursor-pointer"
                )}
                onClick={() => setOpen(true)}
              >
                {t("workflow:createPage.buttonAddStep")}
              </button>
            </div>
          </div>
          <div className="py-4 mx-8 overflow-hidden overflow-x-auto">
            <StepTransitions
              instructions={instructions as WorkflowInstructionDTO[]}
              onSelect={onSelectInstruction}
            />
          </div>

          <div className="flex items-center justify-end gap-4 py-4 mx-8 border-t">
            <button className="px-4 py-2" onClick={handleClose}>
              {t("common:buttonCancel")}
            </button>
            <button
              disabled={requestStatus === RequestStatus.LOADING}
              className="flex items-center justify-center h-10 px-4 text-white rounded bg-primary"
              onClick={handleCreate}
              style={{ minWidth: "10rem" }}
            >
              {requestStatus !== RequestStatus.LOADING && (
                <span>{t("workflow:createPage.textCreateWorflow")}</span>
              )}
              {requestStatus === RequestStatus.LOADING && (
                <VBSpinner className="text-white" />
              )}
            </button>
          </div>
        </div>

        {(open || instruction) && (
          <WorkflowInstructionModal
            visible
            step={instruction?.step || instructions.length + 1}
            instructions={instructions as WorkflowInstructionDTO[]}
            instruction={instruction as WorkflowInstructionDTO}
            onClose={handleCloseInstructionModal}
            onSubmit={handleAddInstruction}
            onEdit={handleEditInstruction}
          />
        )}
      </div>
    </Portal>
  );
};
