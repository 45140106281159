/*
 * File: workspace-users.page.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBPageTitle } from "components/common/vb-page-title.component";
import { VBSpacer } from "components/common/vb-spacer.component";
import { useAppDispatch } from "hooks/use-redux";
import { ChangeEvent, useRef, useState } from "react";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { InviteMemberDialog } from "./components/invite-member.component";
import { RemoveMemberDialog } from "./components/remove-member.component";
import {
  IconDirectDownload,
  IconDirectUpload,
  IconSMSFill,
  IconTrash,
} from "components/common/vb-icon.component";
import { useWorkspaceUsersContext } from "./context/workspace-users.context";
import { WorkspaceUsersTable } from "./components/users-table/users-table.component";
import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import {
  inviteUserAsync,
  loadUsersAsync,
} from "store/customer/users/users.thunk";
import { User } from "domain/customer";
import { UserService } from "services/user-service";
import { delay, downloadTextAsCSV } from "services/common/common.apis";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { Fragment } from "react";
import * as Sentry from "@sentry/react";

export const WorkspaceUsersPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    requesting,
    setRequesting,
    workspace,
    users,
    totalUsers,
    filter,
    setPage,
    setPageSize,
    setSort,
  } = useWorkspaceUsersContext();

  const [showInvite, setShowInvite] = useState(false);
  const [removingMember, setRemovingMember] = useState<User | null>(null);

  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const inputImportRef = useRef<HTMLInputElement>(null);
  const [isShowImportDialog, setIsShowImportDialog] = useState(false);
  const [fileToImport, setFileToImport] = useState<File>();

  function handleInviteMember() {
    setShowInvite(true);
  }

  function closeInvite() {
    setShowInvite(false);
  }

  async function handleInvite(email: string, role: string) {
    if (!workspace) return;
    const payload = {
      group: workspace,
      user: {
        email: email,
        group: workspace,
        role: role,
        userId: email,
        userName: email.substring(0, email.indexOf("@")),
      },
    };
    try {
      setRequesting(true);
      const response = await dispatch(inviteUserAsync(payload));
      handleThunkRejected(response);
      await dispatch(loadUsersAsync());
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      closeInvite();
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || t("member.textInviteFailed");
      dispatch(enqueueErrorNotification(errMessage));
    } finally {
      setRequesting(false);
    }
  }

  function handleRowAction(action: string, data: User) {
    if (action === "delete") {
      handleRemoveMember(data);
    }
  }

  function handleSortChanged(sort: string) {
    setSort(sort);
  }

  function handleRemoveMember(removingMember: User) {
    setRemovingMember(removingMember);
  }

  async function handleExport() {
    try {
      if (isExporting) return;
      setIsExporting(true);
      const res = await UserService.exportWorkspaceUsersToCSV();
      downloadTextAsCSV("users.csv", res.data);
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsExporting(false);
    }
  }

  async function hanldeImportFileChanged(e: ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;
    if (!fileList) return;
    const newFiles: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const newFile = fileList.item(i);
      if (!newFile || newFile?.size > 1024 * 1024 * 5) {
        dispatch(enqueueErrorNotification(t("common:textErrorFileLarge")));
        return;
      }
      newFiles.push(newFile);
    }

    if (newFiles.length <= 0) return;
    setFileToImport(newFiles[0]);
  }

  async function handleSubmitImport() {
    try {
      if (isImporting) return;
      if (!fileToImport) return;
      setIsImporting(true);
      setRequesting(true);

      const res = UserService.importUsersToWorkspaceFromCSV(fileToImport);
      handleThunkRejected(res);
      await delay(3);
      await dispatch(loadUsersAsync());
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      setIsShowImportDialog(false);
      setFileToImport(undefined);
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsImporting(false);
      setRequesting(false);
    }
  }

  return (
    <div className="relative flex flex-col h-full">
      <div className="flex items-center flex-none gap-4 p-6">
        <VBPageTitle text={t("member:pageTitle")} />

        <VBSpacer />

        <button
          className="button-secondary disabled:opacity-50"
          onClick={handleExport}
          disabled={isExporting}
        >
          <span className="hidden lg:inline-block">{t("common:buttonExport")}</span>
          <IconDirectDownload className="flex-none w-5 h-5" />
        </button>

        <button
          className="button-secondary"
          onClick={() => setIsShowImportDialog(true)}
          disabled={isImporting}
        >
          <span className="hidden lg:inline-block">{t("common:buttonImport")}</span>
          <IconDirectUpload className="flex-none w-5 h-5" />
        </button>

        <button className="button-primary" onClick={handleInviteMember}>
          <span>{t("member:buttonInviteMember")}</span>
          <IconSMSFill className="flex-none w-5 h-5" />
        </button>
      </div>

      <div className="flex flex-col flex-auto">
        <div className="flex-auto px-6 overflow-y-auto">
          <WorkspaceUsersTable
            filter={filter}
            users={users}
            onRowAction={handleRowAction}
            onSortChanged={handleSortChanged}
          />
        </div>
        <div className="flex-none px-6 py-4">
          <GridPagination
            page={parseInt(filter.page)}
            pageSize={parseInt(filter.pageSize)}
            pageSizeOptions={[10, 15, 20, 25, 50, 75, 100]}
            totalItems={totalUsers}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
          />
        </div>
      </div>

      <InviteMemberDialog
        visible={showInvite}
        processing={requesting}
        onClose={closeInvite}
        onSubmit={handleInvite}
      />
      <RemoveMemberDialog
        member={removingMember}
        onClose={() => setRemovingMember(null)}
      />

      <VBModal
        open={isShowImportDialog}
        title={"Import users"}
        onClose={() => setIsShowImportDialog(false)}
        textSubmit={t("common:buttonImport")}
        onSubmit={() => handleSubmitImport()}
        disableSubmit={!fileToImport || isImporting}
      >
        <Fragment>
          {
            fileToImport &&
            <div className="flex gap-2 items-center">
              <span className="text-primary">{fileToImport.name}</span>
              <IconTrash
                className="text-error-300 cursor-pointer"
                onClick={() => setFileToImport(undefined)}
              />
            </div>
          }
          {
            !fileToImport &&
            <div className="h-40 border border-dotted rounded relative flex items-center justify-center">
              <span className="text-primary cursor-pointer">Choose file</span>
              <input
                className="w-full h-full opacity-0 absolute cursor-pointer"
                type="file"
                ref={inputImportRef}
                accept="text/csv"
                onChange={hanldeImportFileChanged}
                onClick={(e: any) => {
                  e.target.value = null;
                }}
              />
            </div>
          }
        </Fragment>
      </VBModal>
    </div>
  );
};
