/*
 * File: create-dataset-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 2nd August 2021 2:47:02 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { ChangeEvent, useMemo, useRef, useState } from "react";
import { DatasetDTO } from "services/label-service/dtos";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { createNewDatasetAsync } from "store/customer/dataset/dataset.slice";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { useKeyPress, useLocalStorageState } from "ahooks";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { SelectableInput } from "components/common/vb-input/selectable-input.component";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { selectSupportedDatasourceTypes } from "store/common/app-setting/app-setting.selectors";
import { localStorageKey } from "constants/storage.constant";
import validator from "validator";
import { DataType } from "domain/customer";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import * as Sentry from "@sentry/react";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CreateDatasetDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory()

  const currentUser = useAppSelector(selectCurrentUser);
  const [name, setName] = useState("");
  const inputDescriptionRef = useRef<HTMLTextAreaElement>(null);
  const datasourceTypes = useAppSelector(selectSupportedDatasourceTypes(t));
  const [datasourceType, setDatasourceType] = useLocalStorageState<DataType>(
    localStorageKey.PREFERED_DATASOURCE_TYPE,
    DataType.IMAGE
  );
  const [processing, setProcessing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const isValidInput = useMemo(() => {
    if (validator.isEmpty(name)) return false;
    if (validator.isEmpty(datasourceType)) return false;
    return true;
  }, [datasourceType, name]);

  function handleDataTypeChanged(newValue: any) {
    if (newValue && newValue.value) {
      setDatasourceType(newValue.value);
    }
  }

  function handleNameChange(event: ChangeEvent<any>) {
    setName(event.target.value);
  }

  async function handleSubmit() {
    if (!currentUser) return;
    if (!isValidInput) return;
    if (processing) return;

    try {
      setProcessing(true);
      const description = inputDescriptionRef.current?.value || "";
      const payload: Partial<DatasetDTO> = {
        createdBy: currentUser.uid,
        name: name.trim(),
        category: datasourceType,
        description: description.trim(),
      };
      const response = await dispatch(createNewDatasetAsync(payload));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textCreatedSuccess")));
      response.payload.id && history.push(`${Routes.DATASETS}/${response.payload.id}`)
      onClose && onClose();
    } catch (err) {
      Sentry.captureException(err);
      Logger.log("err", err);
      dispatch(enqueueErrorNotification(t("common:textCreatedFailed")));
    } finally {
      setName("");
      setProcessing(false);
    }
  }

  useKeyPress(KeyboardKey.Enter, handleSubmit, {
    target: containerRef.current,
  });

  return (
    <VBModal
      title={t("dataset:createDialog.title")}
      open={open}
      onClose={onClose}
      textSubmit={t("common:buttonCreate")}
      onSubmit={handleSubmit}
      disableSubmit={processing || !isValidInput}
      blockUI={processing}
      processingIndicator={processing}
    >
      <div className="flex flex-col gap-3" ref={containerRef}>
        <div className="flex flex-col gap-2">
          <div>{t("dataset:createDialog.textDatasourceName")}</div>
          <input
            autoFocus
            type="text"
            className="h-12 px-4 bg-white border rounded border-background-300 focus:border-primary"
            required
            onChange={handleNameChange}
            placeholder={t("dataset:createDialog.placeholder")}
          />
        </div>

        <SelectableInput
          value={datasourceTypes.find((item) => item.value === datasourceType)}
          onChange={handleDataTypeChanged}
          header={t("dataset:createDialog.textDatasourceType")}
          options={datasourceTypes}
          menuPortalTarget={document.body}
        />

        <div className="flex flex-col gap-2">
          <div> {t("dataset:createDialog.textDatasourceDescription")}</div>
          <textarea
            className="h-24 px-4 py-2 bg-white border rounded border-background-300 focus:border-primary"
            ref={inputDescriptionRef}
            placeholder={t("dataset:createDialog.labelDescription")}
          />
        </div>
      </div>
    </VBModal>
  );
};
