import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { EmbeddedType } from "services/label-service/dtos";
import { VBSwitch } from "components/design-system/switch/switch.component";
import { useAppSelector } from "hooks/use-redux";
import { selectActiveLearningVisible } from "store/customer/project/project.selectors";
import { useCreateBatchV2Context } from "../create-batch-v2.provider";
import {
  InstructionUrl,
  InstructionUrlType,
} from "../../create-batch/instruction-url.component";
import { CreateBatchV2PageInfoModel } from "../create-batch-v2.states";

export const CreateBatchV2PageInfo = () => {
  const { t } = useTranslation();
  const { batchInfo, setBatchInfo } = useCreateBatchV2Context();
  const activeLearningVisible = useAppSelector(selectActiveLearningVisible);

  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const instructionRef = useRef<HTMLTextAreaElement>(null);
  const urlTypeRef = useRef<InstructionUrlType | undefined>();
  const urlRef = useRef<string | undefined>();
  const activeLearningSwitchRef = useRef<HTMLInputElement>(null);
  const balanceJobAssignSwitchRef = useRef<HTMLInputElement>(null);
  const [description, setDescription] = useState("");
  const [instruction, setInstruction] = useState("");

  function handleChange(_: any) {
    const name = nameRef.current?.value.trim() || "";
    const description = descriptionRef.current?.value.trim() || "";
    const instruction = instructionRef.current?.value.trim() || "";
    const urlType = urlTypeRef.current;
    const url = urlRef.current;

    const payload: CreateBatchV2PageInfoModel = {
      name,
      description,
      instruction,
      activeLearning: {
        enable: activeLearningSwitchRef.current?.checked || false,
      },
      balanceJobAssign: balanceJobAssignSwitchRef.current?.checked || false,
    };
    if (urlType && url) {
      payload.embeddedType =
        urlType === InstructionUrlType.UPLOADED_FILE
          ? EmbeddedType.PDF
          : EmbeddedType.HTML;
      payload.embeddedContent = url;
    }
    setBatchInfo(payload);
  }

  function handleUrlChanged(
    urlType: InstructionUrlType | undefined,
    url: string | undefined
  ) {
    urlTypeRef.current = urlType;
    urlRef.current = url;
    handleChange(undefined);
  }

  function handleOnDescriptionInput() {
    setDescription((descriptionRef.current?.value as string).trim());
  }

  function handleOnInstructionInput() {
    setInstruction((instructionRef.current?.value as string).trim());
  }

  return (
    <div>
      <div className="text-xl font-bold">
        {t("project:batch.instruction.step0.title")}
      </div>
      <div className="grid grid-cols-1 gap-8 py-6 md:grid-cols-2">
        <div className="space-y-6">
          <div className="space-y-2">
            <div className="text-base font-semibold text-background-700">
              {t("project:batch.instruction.step0.placeholderBatchName")}
            </div>
            <input
              type="text"
              required
              autoFocus
              onChange={handleChange}
              ref={nameRef}
              maxLength={256}
              placeholder={t(
                "project:batch.instruction.step0.placeholderBatchName"
              )}
              defaultValue={batchInfo.name || ""}
              className="w-full px-4 py-2 border border-gray-400 rounded focus:border-primary focus:ring-1 focus:ring-primary"
            />
          </div>
          <div className="space-y-2">
            <div className="flex items-center justify-between text-sm text-background-500">
              <span className="text-base font-semibold text-background-700">
                {t("project:batch.instruction.step0.description")}
              </span>
              <span>{description.length}/500</span>
            </div>
            <textarea
              ref={descriptionRef}
              cols={3}
              onInput={handleOnDescriptionInput}
              maxLength={500}
              onChange={handleChange}
              placeholder={t(
                "project:batch.instruction.step0.placeholderDescription"
              )}
              defaultValue={batchInfo.description || ""}
              className="w-full px-4 py-2 border border-gray-400 rounded focus:border-primary focus:ring-1 focus:ring-primary"
            />
          </div>
          <div className="space-y-2">
            <div className="flex items-center justify-between text-sm text-background-500">
              <span className="text-base font-semibold text-background-700">
                {t("project:batch.instruction.step0.instruction")}
              </span>
              <span>{instruction.length}/500</span>
            </div>
            <textarea
              cols={3}
              maxLength={500}
              onChange={handleChange}
              ref={instructionRef}
              onInput={handleOnInstructionInput}
              defaultValue={batchInfo.instruction || ""}
              placeholder={t(
                "project:batch.instruction.step0.placeholderInstruction"
              )}
              className="w-full px-4 py-2 border border-gray-400 rounded focus:border-primary focus:ring-1 focus:ring-primary"
            />
          </div>
        </div>
        <div className="space-y-6">
          <div className="max-w-md">
            <h3 className="py-2 font-semibold">Workflow settings</h3>
            {activeLearningVisible && (
              <div className="flex items-center gap-2">
                <VBSwitch
                  ref={activeLearningSwitchRef}
                  onChange={handleChange}
                />
                <span className="text-sm text-background-500">
                  {t("project:batch.instruction.step0.activeLearning")}
                </span>
              </div>
            )}

            <div className="flex items-center gap-2">
              <VBSwitch
                ref={balanceJobAssignSwitchRef}
                onChange={handleChange}
              />
              <span className="text-sm text-background-500">
                {t("project:batch.instruction.step0.balanceJobAssign")}
              </span>
            </div>
          </div>
          <InstructionUrl onDataChanged={handleUrlChanged} />
        </div>
      </div>
    </div>
  );
};
