/*
 * File: create-instruction.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";
import { VBRichTextEditor } from "components/common/vb-rich-text/vb-rich-text.component";
import { useStepConditions } from "hooks/workflow/use-step-conditions.hook";
import { useMemo, useState } from "react";
import { useRef } from "react";
import {
  ProjectTypes,
  StepType,
  WorkflowInstructionDTO,
} from "services/label-service/dtos";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { Logger } from "utilities/logger";
import { getNextStepTypes } from "../workflows.util";
import * as Sentry from "@sentry/react";

export type LabelerType = "labeler" | "supervisor";
interface Props {
  step?: number;
  visible: boolean;
  instructions: WorkflowInstructionDTO[];
  instruction: WorkflowInstructionDTO | null;
  projectType?: ProjectTypes;
  onClose(): void;
  onSubmit?(instruction: Partial<WorkflowInstructionDTO>): void;
  onEdit?(instruction: WorkflowInstructionDTO): void;
}

export const WorkflowInstructionModal = ({
  visible,
  instructions,
  instruction = null,
  step = instruction?.step || 1,
  onClose,
  onSubmit,
  onEdit,
}: Props) => {
  const { t } = useTranslation();
  const isEditing = instruction !== null;
  const nextStepTypes = getNextStepTypes(instructions);
  const { stepConditionOptions } = useStepConditions(true);

  const numRoundRef = useRef<HTMLInputElement>(null);
  const descriptionDataRef = useRef(instruction ? instruction.description : "");
  const [canViewPreviousStep, setCanViewPrevious] = useState(
    instruction ? instruction.canViewPreviousStepResult : false
  );
  const [stepType, setStepType] = useState(
    instruction ? instruction.stepType : nextStepTypes[0]
  );
  const scoreThresholdRef = useRef<HTMLInputElement>(null);
  const [processing, setProcessing] = useState(false);
  const [instructionCondtion, setInstructionCondtion] = useState(() => {
    if (instruction) return instruction.condition;
    return "";
  });

  const stepTypeOptions = useMemo(() => {
    return nextStepTypes
      .filter((stepType: StepType) => {
        return stepType !== StepType.NORMAL;
      })
      .map((stepType: StepType) => ({
        label: stepType === StepType.REVIEW ? "Review" : "Acceptance",
        value: stepType,
      }));
  }, [nextStepTypes]);

  const conditionOptions = useMemo(() => {
    if (!instruction || !instruction.condition) {
      return [...stepConditionOptions];
    }
    const conditionIds = instruction.condition
      .split(" ")
      .filter((id) => id !== "||" && id !== "&");
    return stepConditionOptions.filter((stepConditionOption) => {
      if (!stepConditionOption.value) return true;
      const ids = stepConditionOption.value.split(" ");
      for (let conditionId of conditionIds) {
        if (ids.includes(conditionId)) return true;
      }
      return false;
    });
  }, [stepConditionOptions, instruction]);

  const isAcceptanceStep = useMemo(
    () => stepType === StepType.ACCEPTANCE,
    [stepType]
  );

  async function handleSave() {
    if (processing) return;
    try {
      setProcessing(true);
      if (isEditing) {
        await onSave();
      } else {
        await onCreateNew();
      }
    } catch (error) {
      Sentry.captureException(error);
      Logger.log(error);
    } finally {
      setProcessing(false);
    }
  }

  async function onCreateNew() {
    const numRound = parseInt(numRoundRef.current?.value || "1");
    const scoreThreshold = parseFloat(scoreThresholdRef.current?.value || "0");

    const payload: Partial<WorkflowInstructionDTO> = {
      step: step,
      name: t("workflow:instructionmodal.stepName", { step: step }),
      description: descriptionDataRef.current,
      roundNumber: numRound,
      canViewPreviousStepResult: canViewPreviousStep,
      condition: instructionCondtion,
      stepType,
      scoreThreshold,
    };

    onSubmit && onSubmit(payload);
  }

  async function onSave() {
    if (!instruction) return;
    const numRound = parseInt(numRoundRef.current?.value || "1");
    const scoreThreshold = parseFloat(scoreThresholdRef.current?.value || "0");

    const payload: WorkflowInstructionDTO = {
      ...instruction,
      description: descriptionDataRef.current,
      roundNumber: isAcceptanceStep ? 1 : numRound,
      canViewPreviousStepResult: canViewPreviousStep,
      condition: instructionCondtion,
      stepType,
      scoreThreshold,
    };

    onEdit && onEdit(payload);
  }

  function handleDescriptionChange(data: string) {
    descriptionDataRef.current = data;
  }

  const handleChangeStepType = (option: any) => {
    if (!option || !option.value) {
      setStepType(StepType.NORMAL);
      return;
    }
    setCanViewPrevious(true);
    setStepType(option.value);
  };

  const isFirstStep = step === 1;
  return (
    <VBModal
      open={visible}
      onClose={onClose}
      title={
        isEditing
          ? t("workflow:instructionmodal.textEditStep")
          : t("workflow:instructionmodal.textCreateStep")
      }
      textSubmit={isEditing ? t("common:buttonSave") : t("common:buttonCreate")}
      onSubmit={handleSave}
      width="45rem"
    >
      <div className="p-6">
        <div className="grid items-center grid-cols-12">
          <div className="col-span-3 px-4 my-6 text-right">
            {t("workflow:instructionmodal.textStep")}
          </div>
          <div className="col-span-9">
            <input
              readOnly
              defaultValue={step}
              type="number"
              id="step"
              className="w-full px-2 py-2 border rounded text-background-400 border-background-300"
            />
          </div>

          <div className="h-full col-span-3 px-4 my-6 text-right">
            {t("workflow:instructionmodal.textDescription")}
          </div>
          <div className="col-span-9">
            <VBRichTextEditor
              defaultValue={instruction ? instruction.description : ""}
              onChange={handleDescriptionChange}
            />
          </div>

          <div className="h-full col-span-3 px-4 py-4 text-right">
            {t("workflow:instructionmodal.textNumOfRounds")}
          </div>
          <div className="col-span-9 py-4">
            <input
              ref={numRoundRef}
              type="number"
              defaultValue={instruction?.roundNumber || 1}
              id="num_round"
              min={1}
              max={isAcceptanceStep ? 1 : 100}
              className="w-full px-2 py-2 border rounded text-background-700 border-background-300 focus:border-primary"
            />
            {!isFirstStep && (
              <div className="py-2">
                <Checkbox
                  color="primary"
                  checked={canViewPreviousStep}
                  onChange={(_, selected) => setCanViewPrevious(selected)}
                />
                <span>
                  {t("workflow:instructionmodal.textShowPreviousResult")}
                </span>
              </div>
            )}
          </div>

          <div className="h-full col-span-3 px-4 py-4 text-right">
            {t("workflow:instructionmodal.textScoreThreshold")}
          </div>
          <div className="col-span-9">
            <input
              ref={scoreThresholdRef}
              type="number"
              defaultValue={instruction?.scoreThreshold || 0}
              min={0}
              max={1}
              className="w-full px-2 py-2 border rounded text-background-700 border-background-300 focus:border-primary"
            />
          </div>

          {!isFirstStep && !isAcceptanceStep && (
            <>
              <div className="h-full col-span-3 px-4 py-4 text-right">
                <div>{t("workflow:instructionmodal.textConditions")}</div>
              </div>
              <div className="w-full col-span-9 py-4">
                <VBSelectComponent
                  className="w-full"
                  options={conditionOptions}
                  value={conditionOptions.find(
                    (option) => option.value === instructionCondtion
                  )}
                  onChange={(option: any) =>
                    setInstructionCondtion(option.value as string)
                  }
                  menuPortalTarget={document.body}
                />
              </div>
            </>
          )}
          {!isFirstStep && (
            <>
              <div className="h-full col-span-3 px-4 text-right">
                <span>{t("workflow:instructionmodal.verifyMode")}</span>
              </div>
              <div className="col-span-9">
                {isEditing ? (
                  <div className="font-bold text-md">
                    {"=> "}
                    {stepType === StepType.NORMAL
                      ? t("workflow:textNormalMode")
                      : stepType === StepType.REVIEW
                        ? t("workflow:textReviewMode")
                        : t("workflow:textAcceptanceMode")}
                  </div>
                ) : (
                  stepTypeOptions &&
                  stepTypeOptions.length > 1 && (
                    <VBSelectComponent
                      value={stepTypeOptions.find((option) => {
                        return option.value === stepType;
                      })}
                      options={stepTypeOptions}
                      onChange={handleChangeStepType}
                      isClearable
                      menuPortalTarget={document.body}
                    />
                  )
                )}

                {stepType === StepType.ACCEPTANCE && (
                  <div>
                    <p className="mb-2">
                      {t("workflow:instructionmodal.reviewResultDesc")}
                    </p>
                    <div className="flex items-center gap-4">
                      <span>
                        {t("workflow:instructionmodal.reviewResultOption")}
                      </span>
                      <button className="px-4 border rounded border-primary">
                        {t("common:buttonAccept")}
                      </button>
                      <button className="px-4 border rounded border-warning-500">
                        {t("common:buttonReject")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </VBModal>
  );
};
