/*
 * File: vb-search-filter.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { SearchParams } from "models/common/search-params";
import { useKeyPress } from "ahooks";

export function VBSearchFilter({ placeholder }: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);

  useKeyPress(KeyboardKey.Escape, handleCancel, {
    target: inputRef.current,
  });

  useKeyPress(KeyboardKey.Enter, handleSubmit, {
    target: inputRef.current,
  });

  function handleCancel() {
    if (!inputRef.current) return;
    inputRef.current.value = "";
    inputRef.current.blur();
  }

  function handleSubmit() {
    if (!inputRef.current) return;
    inputRef.current.blur();
    const search = inputRef.current.value;
    submitSearch(search);
  }

  function submitSearch(search: string) {
    const urlSearchParams = new URLSearchParams(location.search);
    if (search) {
      urlSearchParams.set(SearchParams.SEARCH, search);
      urlSearchParams.set(SearchParams.PAGE, "1");
    } else {
      urlSearchParams.delete(SearchParams.SEARCH);
    }
    history.push({
      ...location,
      search: urlSearchParams.toString(),
    });
  }
  function getQueryParam(
    urlSearchParams: URLSearchParams,
    key: string,
    defaultValue: string
  ): string {
    const value = urlSearchParams.get(key);
    if (value) return value;
    return defaultValue;
  }
  useEffect(() => {
    const search = getQueryParam(
      new URLSearchParams(location.search),
      SearchParams.SEARCH,
      ""
    );
    if (inputRef.current) inputRef.current.value = search;
  }, [location.search]);

  return (
    <div className="flex items-center gap-3 px-4 bg-white border rounded h-9 border-background-300 focus-within:ring-primary focus-within:ring-1">
      <i className="uir-search"></i>
      <input
        ref={inputRef}
        className="w-24 bg-opacity-0 md:w-32 lg:w-48 focus:outline-none ring-0 focus:ring-0 focus:ring-transparent"
        type="text"
        placeholder={placeholder ? placeholder : t("common:placeholderSearch")}
      />
    </div>
  );
}
