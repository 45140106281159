/*
 * File: project-input-emails.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th September 2021 4:54:41 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

interface Props {
  text: string;
  onChange?(users: string): void;
}

export const ProjectInputEmails = ({ text, onChange }: Props) => {

  return (
    <div className="h-full flex-1 px-4 flex items-start">
      <button className="w-10 h-10 mx-4 my-2 rounded-full bg-gray-200 flex justify-center items-center">
        <i className="uir-plus" />
      </button>
      <textarea
        className="flex-1 h-full p-2 border border-gray-500"
        value={text}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
    </div>
  );
};
