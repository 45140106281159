/*
 * File: project-overview.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 8th July 2021 3:48:43 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBPageTitle } from "components/common/vb-page-title.component";
import { VBSpacer } from "components/common/vb-spacer.component";
import { useEffect, useRef, useState } from "react";
import { ProjectPerformance } from "./performance/project-performance-tab.component";
import { ProjectProgress } from "./progress/project-progress-tab.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  loadProjectObservationsAsync,
  updateProjectDetail,
} from "store/customer/project/project.slice";
import { selectCurrentProject } from "store/customer/project/project.selectors";
import { selectUserWorkspace } from "store/auth/auth.selectors";
import { loadUsersAsync } from "store/customer/users/users.thunk";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { updateProjectAsync } from "store/customer/projects/projects.slice";
import * as Sentry from "@sentry/react";
import { IconButton } from "@material-ui/core";
import { Edit } from "iconsax-react";
import { ProjectDrawer } from "../components/project-drawer/project-drawer.component";

export const ProjectOverviewPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const [open, setOpen] = useState(false);

  const project = useAppSelector(selectCurrentProject);
  const workspaceId = useAppSelector(selectUserWorkspace);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const [projectDescription, setProjectDescription] = useState(() => {
    return project?.description || "";
  });

  useEffect(() => {
    if (!project?.id || !workspaceId) return;
    dispatch(loadProjectObservationsAsync(project.id));
    dispatch(loadUsersAsync());
  }, [project, dispatch, workspaceId]);

  const updateProjectDescription = async () => {
    if (!project) return;

    try {
      const payload = {
        id: project.id,
        name: project.name,
        description: projectDescription || "",
      };
      dispatch(updateProjectAsync(payload));
    } catch (e: any) {
      Sentry.captureException(e);
      console.log(e);
    }
  };

  return (
    <div className="flex flex-col h-full gap-2 p-4">
      <div className="flex items-center">
        <VBPageTitle text={t("project:overview.title")} />
        <VBSpacer />
        <IconButton onClick={() => setOpen(true)}>
          <Edit size={18} color="#3e7df8" />
        </IconButton>
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-background-700">
          {t("project:overview.textDescription")}
        </div>
        <textarea
          ref={descriptionRef}
          className="p-4 border roudned"
          defaultValue={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
          rows={5}
          onBlur={updateProjectDescription}
        />
      </div>
      <Switch>
        <Route exact path={`${path}`} component={ProjectProgress} />
        <Route path={`${path}/progress`} component={ProjectProgress} />
        <Route path={`${path}/performance`} component={ProjectPerformance} />
      </Switch>

      <ProjectDrawer
        open={open}
        project={project}
        handleClose={(data: any) => {
          if (data) {
            if (descriptionRef.current && data.description) {
              descriptionRef.current.value = data.description ?? "";
            }
            dispatch(updateProjectDetail(data));
          }
          setOpen(false);
        }}
      />
    </div>
  );
};
