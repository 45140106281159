/*
 * File: datasets.page.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/use-redux";
import { selectDatasetLoading } from "../../../store/customer/dataset/dataset.selectors";
import {
  loadUserDatasetsAsync,
  removeCurrentDatasetData,
} from "../../../store/customer/dataset/dataset.slice";
import { DatasetDrawer } from "./components/dataset-drawer/dataset-drawer.component";
import { DatasetGrid } from "./components/dataset-grid/dataset-grid.component";
import { useDatasetContext } from "./context/dataset-context";
import { DeleteDatasetDialog } from "./components/delete-dataset/delete-dataset-dialog.component";
import { DatasetDTO } from "services/label-service/dtos";
import { VBEmptyContent } from "../../../components/common/vb-empty-content.component";
import { VBCreateButton } from "components/common/vb-create-button.component";
import { CreateDatasetDialog } from "./components/create-dataset/create-dataset-dialog.component";
import { VBPageTitle } from "components/common/vb-page-title.component";
import { DatasourceFilterComponent } from "./components/datasource-filter/datasource-filter.component";
import { useDatasourceFilters } from "./hooks/use-datasource-filter.hook";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useDatasources } from "./hooks/use-datasources.hook";
import { useUnmount } from "ahooks";
import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { VBComponentRequesting } from "components/common/vb-component-requesting/vb-component-requesting.component";
import { NoSearchResult } from "./components/search/no-result.component";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import ButtonSelectedDatasets from "./components/toolbar/button-selected-datasets";

export const DatasetsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { resetDataset } = useDatasetContext();
  const { totalDataset, datasets } = useDatasources();
  const requesting = useAppSelector(selectDatasetLoading);
  const [dataset, setDataset] = useState<DatasetDTO | null>(null);
  const [deletingDataset, setDeletingDataset] = useState<DatasetDTO | null>(
    null
  );
  const [createDatasetVisible, setCreateDatasetVisible] = useState(false);
  const { filter, setFilter, setPage, setPageSize } = useDatasourceFilters();
  const history = useHistory();
  const [selectedDatasetIds, setSelectedDatasetIds] = useState<number[]>([]);
  const hasFilter = useMemo(() => {
    return (
      Object.keys(filter).filter((key) => filter[key].length > 0).length > 2
    );
  }, [filter]);

  const isEmpty = useMemo(() => {
    return !hasFilter && !requesting && datasets.length === 0;
  }, [requesting, datasets, hasFilter]);

  const noResult = useMemo(() => {
    return hasFilter && !requesting && datasets.length === 0;
  }, [requesting, datasets, hasFilter]);

  function selectDataset(dataset: DatasetDTO) {
    const isExisted = selectedDatasetIds.includes(dataset.id);
    if (isExisted) {
      setSelectedDatasetIds(
        selectedDatasetIds.filter((id) => id !== dataset.id)
      );
    } else {
      setSelectedDatasetIds([...selectedDatasetIds, dataset.id]);
    }
  }

  function reloadData() {
    dispatch(loadUserDatasetsAsync(filter));
    setSelectedDatasetIds([]);
  }

  function handleClearFilter() {
    setFilter({});
  }

  useDeepCompareEffect(() => {
    dispatch(loadUserDatasetsAsync(filter));
  }, [dispatch, filter]);

  useUnmount(() => {
    dispatch(removeCurrentDatasetData());
    resetDataset();
  });

  return (
    <div className="relative flex flex-col w-full min-h-full p-4">
      <div className="flex items-center justify-between flex-none h-16 p-4">
        <VBPageTitle text={t("dataset:pageTitle")} />
        {!isEmpty && (
          <div className="flex items-center gap-4">
            {selectedDatasetIds.length > 0 && (
              <ButtonSelectedDatasets
                datasetIds={selectedDatasetIds}
                reloadData={reloadData}
              />
            )}
            <DatasourceFilterComponent
              filter={filter}
              onChange={setFilter}
              active={hasFilter}
            />
            <button
              className="button-secondary"
              onClick={() => setCreateDatasetVisible(true)}
            >
              {t("dataset:buttonCreateDataset")}
            </button>
            <VBCreateButton
              text={t("dataset:buttonBrowseFiles")}
              onClick={() => {
                history.push(`${Routes.DATASETS}/browse`);
              }}
            />
          </div>
        )}
      </div>
      <div className="flex-auto">
        <DatasetGrid
          selectedDatasetIds={selectedDatasetIds}
          datasets={datasets}
          onSelect={(set) => selectDataset(set)}
          onSelectInfo={(set) => setDataset(set)}
          onDelete={(set) => setDeletingDataset(set)}
        />

        {isEmpty && (
          <VBEmptyContent
            visible={isEmpty}
            iconClass="uir-gallery"
            title={t("dataset:noDatasetTitle")}
            message={t("dataset:noDatasetMessage")}
            ctaText={t("dataset:buttonCreateDataset")}
            onCTAClick={() => setCreateDatasetVisible(true)}
            createIfNotExist
          />
        )}
        {noResult && <NoSearchResult onAction={handleClearFilter} />}
      </div>

      {!isEmpty && (
        <div className="px-4">
          <GridPagination
            page={parseInt(filter.page)}
            pageSize={parseInt(filter.size)}
            pageSizeOptions={[10, 12, 15, 20, 25, 50, 75, 100]}
            onPageSizeChange={setPageSize}
            onPageChange={setPage}
            totalItems={totalDataset}
          />
        </div>
      )}
      <DatasetDrawer
        open={dataset !== null}
        dataset={dataset}
        handleClose={() => setDataset(null)}
      />
      {deletingDataset && (
        <DeleteDatasetDialog
          dataset={deletingDataset}
          handleClose={() => setDeletingDataset(null)}
        />
      )}
      {createDatasetVisible && (
        <CreateDatasetDialog
          open
          onClose={() => setCreateDatasetVisible(false)}
        />
      )}

      {requesting && <VBComponentRequesting />}
    </div>
  );
};
