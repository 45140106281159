/*
 * File: text-input.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 6th December 2021 11:28:46 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Tooltip } from "@material-ui/core";
import { useKeyPress, useMount } from "ahooks";
import { IconClose, IconSearch } from "components/common/vb-icon.component";
import { HTMLProps, useEffect, useRef } from "react";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";

interface Props extends HTMLProps<HTMLInputElement> {
  header?: string;
  clearInput?: boolean;
  inputIcon?: JSX.Element;
  hideInputIcon?: boolean;
  trailingElement?: JSX.Element;
  containerClassName?: string;
  headerClassName?: string;
  hint?: string;
  require?: boolean;
  disableSubmitOnBlur?: boolean;
  onInputChange?(value: string): void;
  onInputSubmit?(value: string): void;
  onClear?(): void;
}
export const VBTextInputComponent = ({
  containerClassName = "",
  headerClassName = "flex-auto",
  header,
  clearInput,
  inputIcon = <IconSearch className="flex-shrink-0 w-4 h-4" />,
  trailingElement = undefined,
  className,
  hint,
  hideInputIcon,
  disableSubmitOnBlur = false,
  require = false,
  onClear,
  onInputChange,
  onInputSubmit,
  autoFocus,
  defaultValue,
  ...rest
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClear = () => {
    if (!inputRef.current) return;
    inputRef.current.value = "";
    inputRef.current.focus();
    onInputChange && onInputChange("");
    onClear && onClear();
  };

  const handleSubmit = () => {
    if (!inputRef.current) return;
    const value = inputRef.current.value.trim();
    onInputChange && onInputChange(value);
    onInputSubmit && onInputSubmit(value);
  };

  function handleBlur() {
    if (disableSubmitOnBlur) return;
    return handleSubmit();
  }
  useKeyPress(
    KeyboardKey.Enter,
    (event) => {
      if (event.ctrlKey || event.metaKey || event.shiftKey || event.altKey)
        return;
      handleSubmit();
    },
    { target: inputRef.current }
  );
  useKeyPress(KeyboardKey.Escape, handleClear, { target: inputRef.current });

  useMount(() => autoFocus && setTimeout(() => inputRef.current?.focus(), 100));

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultValue?.toString() ?? "";
    }
  }, [defaultValue, inputRef]);

  return (
    <div className={`flex flex-col flex-shrink-0 gap-2 ${containerClassName}`}>
      {header && (
        <div className="flex items-center gap-2 text-sm font-semibold">
          <span className={headerClassName}>{header}</span>
          {require && (
            <Tooltip title="This field is required">
              <span className="text-red-500">*</span>
            </Tooltip>
          )}
        </div>
      )}
      <div className="flex items-center w-full gap-2 px-4 overflow-hidden bg-white border border-gray-300 rounded h-9 focus-within:border-primary">
        {!hideInputIcon && inputIcon}
        <div className="flex-1">
          <input
            onBlur={handleBlur}
            ref={inputRef}
            className={`w-full bg-opacity-0 outline-none ${className}`}
            defaultValue={defaultValue}
            {...rest}
          />
        </div>
        {trailingElement}
        {clearInput && inputRef.current?.value.trim() && (
          <button onClick={handleClear} className="flex-shrink-0 w-4 h-4">
            <IconClose className="w-4 h-4" />
          </button>
        )}
      </div>
      {hint && <div className="text-sm text-error-500">{hint}</div>}
    </div>
  );
};
